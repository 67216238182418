<template>
  <form @submit.prevent="save" class="w-m-content" v-if="!loading && !errors && !success">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin" style="color: #293e52">Variável do Sistema</p>
      <!--<div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">Esta seção
        autoriza uma pessoa a acessar o software, com suas respectivas permissões.
      </div>-->
    </div>
    <div class="w-m-body e-input-modern">
      <div style="min-height: 400px">
        <e-row class="m-b-sm font-bold">
          Nome da variável:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input autofocus simple-border required placeholder="Sem espaços ou acentos. Ex.: LEILOEIRO.NOME" v-model="model.name" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs font-bold">
          O que a variável resulta? Valor:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input simple-border required placeholder="Ex.: Tiago Felipe" v-model="model.value" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs font-bold">
          Decrição:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input simple-border v-model="model.description" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs">
          <label class="flex items-center">
            <u-checkbox v-model="model.active" />
            <span class="font-13 m-l-xs">Ativo</span></label>
        </e-row>


      </div>
    </div>
    <div class="w-m-footer text-right">
      <u-btn @click="excluir" v-if="id" label="Excluir" no-caps color="negative"
             class="b-radius-3px pull-left" />
      <u-btn type="submit" :label="id ? 'Atualizar' : 'Cadastrar'" :disable="!ready" no-caps :color="ready ? 'primary' : 'blue-grey-2'"
             class="b-radius-3px" />
    </div>
  </form>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Variável cadastrada com sucesso!</h5>
    <div class="m-t text-blue-grey-7">
      Ela poderá ser utilizada em todo o sistema de templates/documentos.
    </div>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import ERow from '../../../layout/components/Row'
import {ErpInput} from 'uloc-vue-plugin-erp'
import ECol from '../../../layout/components/Col'
import {UCheckbox} from 'uloc-vue'
import WindowSuccess from '../../../layout/window/Success'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowLoading from '../../../layout/window/Loading'
import {newVariavel as create, updateVariavel as update, find, deleteVariavel} from '../../../../domain/variaveis/services'

let mock = {
  id: null,
  name: null,
  value: null,
  description: null,
  active: true
}

export default {
  name: 'VariavelWindowModern',
  components: {WindowLoading, TimerCloseWindow, WindowSuccess, ECol, ERow, ErpInput, UCheckbox},
  props: ['props', 'id'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      loading: !!this.id,
      errors: null,
      success: false,
      status: null
    }
  },
  mounted () {
    this.id && this.load()
  },
  computed: {
    mock () {
      return mock
    },
    ready () {
      let model = this.model
      if (String(model.name).length > 2 && model.value) {
        return true
      }
      return false
    }
  },
  methods: {
    load () {
      find(this.id)
      .then(response => {
        this.model = response.data
        this.loading = false
      })
      .catch(error => {
        this.alertApiError(error)
        this.loading = false
      })
    },
    excluir () {
      this.$uloc.dialog({
        title: `Tem certeza que deseja excluir esta variável?`,
        message: 'Templates que a utilizam podem ser afetados. Digite EXCLUIR e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'negative'
      }).then(data => {
        if(data.toUpperCase() !== 'EXCLUIR') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Exclusão não efetuada.`
          })
          return
        }
        this.$uloc.notify({
          color: 'negative',
          message: `Excluindo variável...`
        })
        deleteVariavel(this.id)
          .then(response => {
            this.$uloc.notify({
              color: 'positive',
              message: `Variável deletado com sucesso!`
            })
            this.$uloc.window.emit(this.$root.wid, 'created', this.props)
            this.$uloc.window.close(this.$root.wid)
          })
          .catch(error => {
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    save () {
      console.log('Save')
      this.loading = true

      let data = this.model

      let method = this.id ? (data) => update(this.id, data) : create
      method(data)
        .then(({data}) => {
          this.success = true
          Object.assign(this.model, data)
          this.$nextTick(() => {
            this.$uloc.window.emit(this.$root.wid, 'created', this.props)
            // this.$refs.timer.start()
          })
        })
        .catch(error => {
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    }
  }
}
</script>
